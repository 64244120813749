

import React, { Component, Fragment, Text } from "react";
import logo from './logo.svg';
import './App.css';
import fire from "./components/config/fire";
import firebase from "firebase";
import VirtualList from 'react-virtual-list';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ReactDOM from "react-dom";
import LiveMatch from "./LiveMatch";
import Landing from "./Landing"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";





class App extends Component {
  constructor(props) {
    super(props);
    this.state ={
    }
  }



  


componentDidMount() {
 // this.getMatchVenueList()
}



render() { 
  return (
  
    <Router>
    <div>
      <Switch>
        
        <Route path="/livematch/:name" render={(props) => <LiveMatch {...props} />} />
        <Route path="/">
          <Landing />
        </Route>
      </Switch>
    </div>
  </Router>
);
}

}




export default App;
