

import React, { Component, Fragment, Text } from "react";
import logo from './icon.png';
import './App.css';
import fire from "./components/config/fire";
import firebase from "firebase";
import VirtualList from 'react-virtual-list';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


class Landing extends Component {
  constructor(props) {
    super(props);
    this.state ={
playerTwoNames: [],
matchArray: [],
liveArray: [],
name: 'Enter Match ID'
    }
    this.handleChange = this.handleChange.bind(this);
  }


  


getMatches(e) {

  return 
         (
   <Fragment>{e}</Fragment>
         )
          }
  
 viewLive (e) {
            var that = this;
            e.preventDefault();
        
            // Get values via this.refs
            var data2 = {
              email: ReactDOM.findDOMNode(this.refs.email).value
            }
            this.navigation.navigate('')
          }




liveMatches() {

  const feed = [{
    matchArray: this.state.matchArray,
    matchVenueArray: this.state.playerTwoNames
  }];
  
  const App = () => {
    return feed.map((rowData, index) => {
      return rowData.matchArray.map((c, i) => {
        if(rowData.matchVenueArray[i])

          return (
            <Fragment>
                      
        <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey={i+1}>
              {rowData.matchVenueArray[i]}

              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={i+1}>
              <Card.Body>{this.getMatches( rowData.matchVenueArray[i]) }</Card.Body>
            </Accordion.Collapse>
          </Card>
  
        
         </Fragment> 
          );
      });
    })
  }
  return (
  <App />
  )
}

viewLive (e) {
  var that = this;
  e.preventDefault();

  // Get values via this.refs
  var data2 = {
    matchID: ReactDOM.findDOMNode(this.refs.matchID).value,
  };


  
};


handleChange(event) {    this.setState({name: event.target.value});  }

componentDidMount() {
 // this.getMatchVenueList()
}



render() { 
  console.log(this.state.matchArray)
  return (

   <div>
       <center>
     
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Welcome to rollUp Live
        </p>


<input
        className="form-control"
          ref="matchID"
          placeholder="Enter Match ID"
          style={{ marginBottom: "0.8rem", width: "20%" }}
          onChange={this.handleChange}
        />



<Link to={'/livematch/'+this.state.name} className="btn btn-primary">View Live Scores</Link>

</center>

    </div>
  
    
    );
  }
}



export default Landing;
