

import React, { Component, Fragment, Text } from "react";
import logo from './icon.png';
import './App.css';
import fire from "./components/config/fire";
import firebase from "firebase";
import VirtualList from 'react-virtual-list';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";



class LiveMatch extends Component {
  constructor(props) {
    super(props);
    this.state ={
playerTwoNames: [],
matchArray: [],
liveArray: [],
id: this.props.match.params.name
    }
  }


  
getMatchVenueList() {

  var that = this
  
 var docRef = firebase.firestore().collection("live").doc(that.state.id);

 docRef.get().then(doc => {
      that.setState({ data: doc.data(), playerOneName: doc.data().playerOneName, playerTwoName: doc.data().playerTwoName, playerOneScore: doc.data().playerOneScore, playerTwoScore: doc.data().playerTwoScore, ends: doc.data().endNumber, matchVenue: doc.data().matchVenue, matchDate: doc.data().matchDate })
      

        console.log(doc.data().playerOneName)


  });


}


getMatchVenueListLive() {

  var that = this
  
 var docRef = firebase.firestore().collection("live").doc(that.state.id);

 docRef.onSnapshot(querySnapshot => {
  console.log(`Received query snapshot of size ${querySnapshot.size}`);
  docRef.get().then(doc => {
    that.setState({ data: doc.data(), playerOneName: doc.data().playerOneName, playerTwoName: doc.data().playerTwoName, playerOneScore: doc.data().playerOneScore, playerTwoScore: doc.data().playerTwoScore, ends: doc.data().endNumber })
 })
 })
}




componentDidMount() {
this.getMatchVenueList()
this.getMatchVenueListLive()
}



render() { 
  console.log(this.state.matchArray)
  console.log(this.state.id)

  return (
    <div className="App">
           <p className="PlayerNames">
          {this.state.playerOneName} v {this.state.playerTwoName}
        </p>

             <h1 className="Scores">
          {this.state.playerOneScore} - {this.state.playerTwoScore}
        </h1>
        <p>After {this.state.ends} ends</p>
        <p>
          Live Scores from {this.state.matchVenue}, Match commenced: {this.state.matchDate}
        </p>
   
        <img src={logo} className="App-logo-live" alt="logo" />

   






    </div>
    );
  }
}



export default LiveMatch;
